// `build:production:mr` replaces `environment.ts` with `environment.production-mr.ts`.
// The list of file replacements can be found in `angular.json`.

import { API, V1, V2 } from '@leap-common/constants/environments';
import Organization from '@apps/leap/src/app/shared/enums/organization.enum';
import AppSwitcherItem from '@apps/leap/src/app/shared/interfaces/app-switcher-item.interface';
import FeatureConfig from '@leap-libs/feature-flags/src/lib/feature-config.type';

const BASE_URL: string = 'https://app-phi.pipacorp.com';

export const environment = {
    production: true,
    serverUrl: `${BASE_URL}/iam/${API}/${V1}`,
    notebookServerUrl: `${BASE_URL}/notebooks/${API}/${V1}`,
    autocompleteServerUrl: `${BASE_URL}/autocomplete/${API}/${V1}`,
    discoveryServerUrl: `${BASE_URL}/literature-comprehension/${API}/${V1}`,
    metadataServerUrl: `${BASE_URL}/metadata/${API}/${V1}`,
    metapathsServerUrl: `${BASE_URL}/graphmeta/${API}/${V1}`,
    uploadServerUrl: `${BASE_URL}/datalake/${API}/${V1}`,
    notificationsServerUrl: `${BASE_URL}/notifications/${API}/${V1}`,
    pipelineExecutionsServerUrl: `${BASE_URL}/execution-engine/${API}/${V1}`,
    projectsServerUrl: `${BASE_URL}/projects-service/${API}/${V2}`,
    projectsReportServerUrl: `${BASE_URL}/projects-service-report/${API}/${V1}`,
    gptServerUrl: `${BASE_URL}/llm-interface/${API}/${V1}`,
    reportServerUrl: `${BASE_URL}/isr/${API}/${V1}`,
    dairyProfilerServerUrl: `${BASE_URL}/dairy-profiler/${API}/${V1}`,
    profilerServerUrl: `${BASE_URL}/profiler/${API}/${V1}`,
    profilerAutocompleteServerUrl: `${BASE_URL}/autocomplete-profiler/${API}/${V1}`,
    ingredientAnalyzerServerUrl: `${BASE_URL}/analyzer/${API}/${V1}`,
    domain: 'pipacorp.com',
    jupyterHubDomain: 'https://jupyterhub-phi.pipacorp.com',
    organization: Organization.mr,
    product: 'PHI',
    app: 'phi',
    gaKey: 'G-L1QRHMJ0X8',
    gtKey: 'GTM-5GS79FW',
    oktaClientId: '',
    oktaIssuer: 'https://default.okta.com/oauth2/default', // a default value is provided to prevent app errors when OktaAuth is not enabled
    oktaRedirectUri: `${BASE_URL}/auth/external-login`,
    oktaScopes: [''],
};

export const featureFlags: FeatureConfig = {
    appSwitcher: true,
    layoutLanding: true,
    discovery: true,
    notebooks: true,
    projects: true,
    conversationalSearch: false,
    articlesBrowser: true,
    cowMilkProfiler: false,
    dairyProfiler: false,
    ingredientProfiler: false,
    ingredientViewer: false,
    ingredientComparison: false,
    ingredientManagement: false,
    support: true,
    suggest: true,
    faqs: true,
    credits: true,
    feedback: true,
    plantVersion: false,
    ontologies: false,
    explorationPaths: true,
    cowMilkConcentration: false,
    prevalence: false,
    profileCategory: false,
    gpt: true,
    chatAssistant: false,
    externalPrivacyLinks: true,
    externalLogin: false,
    departments: false,
    relationshipOriginFilter: true,
    associationScore: true,
    novelAssociations: true,
    discoveryPreloaderText: true,
    alternativeDiscoveryColumnsOrder: false,
    tagGroups: true,
    healthLabels: true,
    moleculesWeight: false,
    molecules: false,
    labs: false,
    studyTypes: true,
    journals: true,
    dmdDatabase: false,
    emptyDatabases: true,
    proteinOrigins: false,
    literatureFilterSelector: true,
    moleculeTypeFilterSelector: false,
    healthLabelFilterTooltips: false,
    databaseFilterTooltips: false,
    articlesReport: true,
    contentWriter: true,
    animalMilk: false,
    articlesIngestionDate: true,
    promoNotification: true,
};

export const appSwitcherItems: AppSwitcherItem[] = [
    {
        id: 'phi',
        title: 'PHI',
        url: BASE_URL,
        isDisabled: true,
    },
    {
        id: 'phiplant',
        title: 'PHI Plant version',
        url: 'https://app-phi-plants.pipacorp.com',
        isDisabled: false,
    },
];
